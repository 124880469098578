import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import HeaderCase from 'blocks/case/headerCase'
import SectionHalfIntroduction from 'blocks/case/sectionHalfIntroduction'
import SectionHeadingFullWidth from 'blocks/case/sectionHeadingFullWidth'
import Dinamiza from 'components/colorDinamiza'
import SectionTextImageContainer from 'blocks/case/sectionTextImageContainer'
import ParagraphLine from 'components/paragraphLine'
import Image from 'components/image'
import style from 'scss/pages/projects/vega.module.scss'
import SectionDark from 'components/sectionDark'
import SectionTextImageFullWidth from 'blocks/case/sectionTextImageFullWidth'
import SectionSlider from 'blocks/case/sectionSlider'
import FooterCase from 'blocks/case/footerCase'

class Ycj extends React.Component {

  render() {

    const { location, data } = this.props

    const imagePath = '/img/projects/ycj/'

    const headerInfo = {
      data: "22.08.2019",
      color: "#292929",
      logoUrl: imagePath+'logo_ycj.svg',
      image: imagePath+'copertina_ycj.jpg',
      title: "Il brand al centro del progetto visivo",
      tags: ["UI-UX DESIGN","BRAND IDENTITY","PROJECT MANAGEMENT","WEB DEVELOPMENT"]
    }

    const slideImages = ['/img/screenshot/ycj1.jpg','/img/screenshot/ycj2.jpg','/img/screenshot/ycj3.jpg','/img/screenshot/ycj4.jpg']

    return(
      <div>
        <Layout location={location}>
          <Meta site={siteMetadata} title="YCJ" description={'Dal concept alla realizzazione del tuo sito internet o ecommerce. Lavoriamo sull\'immagine del tuo brand per aumentarne la percezione.'} />

          <HeaderCase data={headerInfo} />

          <SectionHalfIntroduction>
            <h2>Digital Identity & UI nel settore <Dinamiza>Travel Experience</Dinamiza></h2>
            <p>Your Custom Journey organizza esperienze di viaggio in Italia per il mercato USA.
              La richiesta attraverso il restyling del sito era quella di comunicare i concetti di italianità ed esperienza esclusiva.
              Per trasmettere la sensazione di unicità e lusso senza eccessi, sono stati scelti colori tenui tendenti all'oro e uno stile pulito e minimale.</p>
          </SectionHalfIntroduction>

          <SectionTextImageFullWidth textPosition={'left'} padding={false} imageFade={false}>
            <img src={imagePath+'journey_ycj.png'} className={style.page__firstImage} />
            <ParagraphLine>
              <h2 data-title>UX & Journey map</h2>
              <p>Attraverso benchmark, empathy map e questionari rivolti all'utente finale è stata delineata la struttura del sito, semplificando la navigazione e organizzando la struttura in modo logico e coerente. Nel workflow seguito siamo partiti dal bisogno dell'audience di essere ispirata e prefigurarsi il viaggio attraverso un racconto semplice ma d'impatto. </p>
            </ParagraphLine>
          </SectionTextImageFullWidth>

          <SectionDark imageSrc={imagePath+'bg_ycj.jpg'} imagePosition={'top'}>

            <SectionTextImageFullWidth textPosition={'right'} padding={false} imageFade={false}>
              <div className={style.page__secondImage}>
                <img src={imagePath+'mockup_ycj.png'} />
              </div>
              <ParagraphLine>
                <h2 data-title className={`has-color-primary-negative`}>Non luoghi perfetti ma destinazioni parlanti</h2>
                <p className={`has-color-primary-negative`}>Attraverso il progetto visivo e la scelta delle immagini i luoghi prendono vita, si animano per dare la sensazione di socialità, convivialità e autenticità.
                  Location artistiche, mercati cittadini, borghi medievali e piatti tipici non perfetti mostrano un'Italia da vivere, da assaporare anche nella sua imperfezione.</p>
              </ParagraphLine>
            </SectionTextImageFullWidth>

            <SectionSlider slidesData={slideImages}>
              <h2 className={`has-color-primary-negative`}>Risultati</h2>
              <p className={`has-color-primary-negative`}>Un'identità visiva che lascia spazio al contenuto, in tutta la sua autenticità. Uno storytelling non fittizio che invoglia l'utente a scoprire di persona gli itinerari proposti con uno stile con pochi fronzoli ma di classe.</p>
            </SectionSlider>

          </SectionDark>

          <FooterCase prevProject={'materica'} nextProject={'vega'} nextProjectColor={'#b23433'} />

        </Layout>
      </div>
    )

  }
}

export default Ycj
